import React, { useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { getHeaders } from '../../request';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import {
    SttLoading,
    SttTranslateHook,
    SttAlerta
} from '@stt-componentes/core';
import { MODALIDADE, ORIGEM_PACIENTE_PROVISORIO } from '../../common/Constants';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = (props) => {
    const { user, ...other } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [gerandoPdf, setGerandoPdf] = useState(false);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    const imprimir = (idExame) => {
        setGerandoPdf(true);
        const idCodificado = Buffer.from(idExame.toString()).toString('base64');
        const opcoes = {
            headers: getHeaders(),
            params: {
                basename: global.gConfig.basename,
                modulo: global.gConfig.modulo_nome,
                versao: global.gConfig.modulo_versao,
                imagens: true
            }
        }

        axios
            .get(`${EXAME_API_BASE_URL}/exame/${idCodificado}/imprimir`, opcoes)
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setGerandoPdf(false);
            });
    }

    const imprimirProtocolo = (exame) => {
        setGerandoPdf(true);
        const idExameCodificado = Buffer.from(exame.id_exame?.toString() || exame.id?.toString()).toString('base64');
        const params = { 
            idExame: idExameCodificado,
            basename: global.gConfig.basename
        };
        axios
            .get(`${EXAME_API_BASE_URL}/protocolo`, { params, headers: getHeaders() })
            .then((response) => {
                window.open(response.data);
            })
            .catch(err => {
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemErro(strings.mensagemErroGeral);
                setErro(true);
            }).finally(() => {
                setGerandoPdf(false);
            });;
    }

    const imprimirTermo = (exame) => {
        setGerandoPdf(true);
        const provisorio = exame.origem_paciente === ORIGEM_PACIENTE_PROVISORIO;
        let url = `${ELETRO_API_BASE_URL}/termo-autorizacao/${btoa(exame.id_paciente)}/${provisorio}`;
        axios
            .get(url, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    let tab = window.open();
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemErro(strings.erroGerarTermo);
                setErro(true);
            })
            .finally(() => {
                setGerandoPdf(false);
            });;

    }

    const handleCloseAlerta = () => {
        setErro(false);
    }

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: handleCloseAlerta
    }];

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf}
                    text={strings.gerandoDocumento}
                />
            </div>

            <SttAlerta
                open={erro}
                title={tituloAlerta}
                message={mensagemErro}
                type="error"
                options={opcoesAlerta}
                onClose={handleCloseAlerta}
            />
        </>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Aba);